/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import '~bs-stepper/dist/css/bs-stepper.min.css';

//mat datePicker
.mat-datepicker-content .mat-calendar{
  width: unset !important;
  height: unset !important;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  // overflow-x: hidden; 

}


.cdk-overlay-container {
  position: fixed;
  z-index: 10000;
}




.red1-snackbar {
  background: #FE6161;
  color: whitesmoke;
  font-weight: bold;
}


.green-snackbar {
  background: #2CD157;
  color: whitesmoke;
  font-weight: bold;
}


.yellow-snackbar {
  background: #F7DC6F;
  color: #FBFCFC;
  font-weight: bold;
}

.btn-blue{
  color: white;
  background-color: blue;
}




.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: rgb(66, 134, 244);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: rgb(66, 134, 244);
  background-color: rgb(66, 134, 244);
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(255, 37, 37, .26);
}






mat-sidenav {
  width: 285px;
  padding: 15px;
  overflow-y: hidden !important;
}

.mat-drawer {
  background-color: #F2F3F4;
  font-weight: bold;
  ;
  color: black;
  overflow-y: hidden !important;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.4), 0 26px 26px 0 rgba(0, 0, 0, 0.19);
}

.main-content {
  padding: 20px;
}







button:focus,
button:active:focus,
button.active:focus {
  outline: none !important;
  outline-style: none !important;
}




/* Ensure that the demo table scrolls */
th,
td {
  white-space: nowrap;
  font-size: 11px !important;
}

div.dataTables_wrapper {
  margin: 0 auto;
}


.material-icons {
  vertical-align: bottom;
}

//dataTable scss

th,
td {
  // width: 150px;
  // text-align: center;
  // border: 1px solid black;
  padding: 6px !important;
}

div.dataTables_wrapper {
  margin: 0% !important;
}

// end dataTable scss






//********************************** Custom Classes For All ***************************
.btn-cancel{
  color: red; font-size:18px; padding: 0%; position: absolute;
}


.spinner {
  padding: 15px;
  position: relative;
  margin-top: 18% !important;
  margin-left: 64% !important;
  font-size: 15px;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}



.tabsettings{
font-weight: 450; 
font-size: 12px; 
height: 30px; 
color: tomato;

}
.tabsettings:hover{
  color: tomato;
}

.mat-form-field{
  font-size: 15px;
}
.ng-select {
  font-size: 15px;
}